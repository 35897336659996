/* eslint-disable class-methods-use-this */
import _axios, { get, put, post, _delete } from '@/lin/plugin/axios'

class Merchant {
  // 获取商家行业类型列表
  async getMerCateList(data) {
    const res = await get('/v1/bus_category', data)
    return res
  }

  // 获取商家行业类型列表-不分页
  async getMerCateListAll(data) {
    const res = await get('/v1/bus_category/all', data)
    return res
  }

  // 添加商家行业
  async addMerCate(data) {
    const res = await post('/v1/bus_category', data)
    return res
  }

  // 修改商家行业
  async editMerCate(data) {
    const res = await put(`/v1/bus_category/${data.id}`, {
      name: data.name,
    })
    return res
  }

  // 删除商家行业
  async deleteMerCate(id) {
    const res = await _delete(`/v1/bus_category/${id}`)
    return res
  }

  // 获取商家列表
  async getMerchantList(data) {
    const res = await get('/v1/business', data)
    return res
  }

  // 获取商家列表-全部
  async getMerchantListAll(data) {
    const res = await get('/v1/business/all', data)
    return res
  }

  // 添加商家
  async addMerchant(data) {
    const res = await post('/v1/business', data)
    return res
  }

  // 修改商家
  async editMerchant(data) {
    const res = await put(`/v1/business/${data.id}`, data)
    return res
  }

  // 删除商家
  async deleteMerchant(id) {
    const res = await _delete(`/v1/business/${id}`)
    return res
  }

  // 修改商家状态
  async editMerchantStatus(id) {
    const res = await put(`/v1/business/${id}/status`)
    return res
  }

  // 批量修改商家行业
  async batchEditMerCate(data) {
    const res = await put('/v1/business/cate', data)
    return res
  }

  // 获取商家详情
  async getMerchantDetail(id, data) {
    const res = await get(`/v1/business/${id}`, data)
    return res
  }
}

export default new Merchant()
